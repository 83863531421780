import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'; // Notice the change from 'Switch' to 'Routes'
import Layout from './components/Layout';
import RoutesPage from "./pages/RoutesPage";
import RouteObjectPage from './pages/RouteObjectPage';
import RoutesPreviewPage from "./pages/RoutesPreviewPage";
import SetDataComponent from "./components/SetDataComponent";
import './assets/css/app.css';
import './icons/css/line-awesome.min.css'

function App() {
    const api: string = 'https://visitsofia.webnovel.bg'
    const [settings, setSettings] = useState<any>({});

    function updateCss() {

        const setData = SetDataComponent(api, 'widget-settings', setSettings)

        console.log(settings);
        if (settings['main'] && settings['main-active']) {
            const newCss = `
        :root {
        --main: ${settings['main']};
        --main-active: ${settings['main-active']};
        }`
            const style = document.createElement('style');
            style.innerHTML = newCss;

            document.head.appendChild(style);
        }
    }

    updateCss();

    return (
        <Router>
            <Layout>
                <Routes> {/* Use Routes instead of Switch */}
                    <Route path="/:lg" element={<RoutesPage api={api}/>}/>
                    <Route path="/:lg/route-preview/:id" element={<RoutesPreviewPage api={api}/>}/>
                    <Route path="/:lg/route/:id" element={<RouteObjectPage api={api}/>}/>
                </Routes>
            </Layout>
        </Router>
    );
}

export default App;
