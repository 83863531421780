import React, {useState} from "react";
import SetDataComponent from "../components/SetDataComponent";
import {IMapObject} from "../interfaces/Interfaces";
import {Link, useParams} from "react-router-dom";
import i18n from "../i18n";
import domAction from "../domAction";

function RoutesPage({api}: IMapObject) {

    const [data, setData]: any = useState([]);
    const {lg} = useParams();
    const getData = SetDataComponent(api, `route-types?lg=${lg}`, setData)
    const ts = i18n(lg)
    return (
        <div className={'containers'} onLoad={domAction.setIframeHeight}>
            <div className={'inner-containers'}>
                <div className={'center top50 page-title'}>{ts.routes}</div>
                <div className={'box-elements bm3 top30'}>
                    {data.map((r: any, index: any) => {
                        let genUrl = `/${lg}/route-preview/${r.id}`;
                        if(r.oneRoute) {
                            genUrl = `/${lg}/route/${r.oneRoute}`
                        }
                        return (
                            <Link to={genUrl} key={index} className={'box-element'}>
                                <div className="img">
                                    <img src={r.img}/>
                                </div>
                                <div className={'title'}>
                                    {r.title}
                                </div>
                            </Link>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}

export default RoutesPage;
